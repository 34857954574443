import { Component, OnInit, Directive } from "@angular/core";
import {
   ValidatorFn,
   AbstractControl,
   Validator,
   ValidationErrors,
   NG_VALIDATORS,
   FormBuilder,
   FormGroup,
   Validators,
   FormArray,
} from "@angular/forms";
import {
   Troncal,
   TroncalPrefijo,
   ETroncalCodec,
} from "../../_interfaces/troncal";
import * as _ from "lodash";
import { of, Observable } from "rxjs";
import { flatMap, concat, tap, filter } from "rxjs/operators";

import { TroncalService } from "../../_services/troncal.service";
import { SocketService, EComando } from "../../_services/socket.service";

@Directive({
   selector: "[startsWithLetters][ngModel]",
   providers: [
      {
         provide: NG_VALIDATORS,
         useExisting: StartsWithLettersValidator,
         multi: true,
      },
   ],
})
export class StartsWithLettersValidator implements Validator {
   validator: ValidatorFn;

   constructor() {
      this.validator = startsWithLettersFactory();
   }

   validate(c: AbstractControl): ValidationErrors {
      return this.validator(c);
   }
}

function startsWithLettersFactory(): ValidatorFn {
   return (comp: AbstractControl) => {
      const currentValue = comp.value;
      // Checks if starts with Letters.
      if (/^[A-Za-z][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$/.test(currentValue)) {
         return null;
      } else {
         return {
            startsWithLettersError: true,
         };
      }
   };
}

@Component({
   selector: "troncal-tag",
   templateUrl: "troncal.component.html",
})
export class TroncalSetupComponent implements OnInit {
   readOnly = false;
   troncal: Troncal;
   secretView = false;
   formTroncal: FormGroup;
   troncal$: Observable<Troncal[]>;

   constructor(
      private $troncal: TroncalService,
      private $monitor: SocketService,
      private $fb: FormBuilder
   ) {}

   get f() {
      return this.formTroncal.controls;
   }
   get codecControlArray() {
      return this.formTroncal.get("codec") as FormArray;
   }
   get prefijosArray() {
      return this.formTroncal.get("prefijos") as FormArray;
   }

   ngOnInit() {
      this.troncal$ = this.$troncal.getTroncales();
      const codecsDisponibles = Object.values(ETroncalCodec);

      const arrayCodecs = this.$fb.array(
         codecsDisponibles.map(() =>
            this.$fb.group({
               nombre: "",
               presente: "",
            })
         )
      );

      this.formTroncal = this.$fb.group({
         protocolo: ["", Validators.required],
         transport: ["", Validators.required],
         nombre: ["", [Validators.required, startsWithLettersFactory()]],
         callerid: "",
         domain: "",
         usuario: "",
         password: "",
         host: ["", Validators.required],
         type: ["", Validators.required],
         dtmf: ["", Validators.required],
         nat: ["", Validators.required],
         rtp: "",
         prefijos: this.$fb.array([]),
         codec: arrayCodecs,
      });

      this.formTroncal.patchValue({
         type: "FRIEND",
         dtmf: "rfc2833",
         nat: "auto_comedia",
         codec: this.patchCodecs(),
      });
   }

   patchCodecs() {
      const codecsDisponibles = Object.values(ETroncalCodec);
      return codecsDisponibles.map((codec) => ({
         nombre: codec,
         presente: false,
      }));
   }

   agregarPrefijo(arrayControl: FormArray): void {
      arrayControl.push(this.crearPrefijo());
   }

   crearPrefijo(prefijo?: TroncalPrefijo): FormGroup {
      const prefijoControl = this.$fb.group({
         troncal: "",
         prioridad: ["", Validators.required],
         contexto: ["", Validators.required],
         prefijo: "",
      });

      if (!!prefijo) {
         prefijoControl.patchValue(prefijo);
      }
      return prefijoControl;
   }

   nuevaTroncal() {
      this.troncal = new Troncal();
      this.readOnly = false;
      this.formTroncal.reset();
      this.formTroncal.get("nombre").enable();
      this.formTroncal
         .get("nombre")
         .setValidators([Validators.required, startsWithLettersFactory()]);
      this.formTroncal.get("protocolo").enable();
      this.formTroncal.get("protocolo").setValidators(Validators.required);

      const prefijosArray = this.formTroncal.get("prefijos") as FormArray;
      for (let index = prefijosArray.length - 1; index >= 0; index--) {
         prefijosArray.removeAt(index);
      }
      this.formTroncal.patchValue({
         type: "FRIEND",
         dtmf: "rfc2833",
         nat: "auto_comedia",
         codec: this.patchCodecs(),
      });
   }

   seleccionarTroncal(troncal: Troncal) {
      this.troncal = { ...troncal };
      this.readOnly = true;

      console.log(troncal);
      const codecsEnTroncal = (troncal.codec as { codec: string }[]).map(
         (c) => c.codec
      );
      const defaultPatch = Object.values(ETroncalCodec).map((codec) => {
         return {
            nombre: codec,
            presente: codecsEnTroncal.includes(codec),
         };
      });

      this.formTroncal.patchValue({
         callerid: troncal.callerid,
         protocolo: troncal.protocolo,
         transport: troncal.transport,
         nombre: troncal.nombre,
         usuario: troncal.usuario,
         password: troncal.password,
         domain: troncal.domain,
         host: troncal.host,
         type: troncal.type,
         dtmf: troncal.dtmf,
         nat: troncal.nat,
         rtp: troncal.rtp,
         prefijos: troncal.prefijos,
         codec: defaultPatch,
      });

      this.formTroncal.get("nombre").clearValidators();
      this.formTroncal.get("nombre").disable();
      this.formTroncal.get("protocolo").clearValidators();
      this.formTroncal.get("protocolo").disable();
      const prefijosArray = this.formTroncal.get("prefijos") as FormArray;

      // limpiar el arreglo
      for (let index = prefijosArray.length - 1; index >= 0; index--) {
         prefijosArray.removeAt(index);
      }

      troncal.prefijos.forEach((prefijo) => {
         const prefijoCtrl = this.crearPrefijo(prefijo);
         prefijosArray.push(prefijoCtrl);
      });
   }

   presente(codec: string): boolean {
      const codecs = (this.troncal.codec as { codec: string }[]).map(
         (c) => c.codec
      );
      return codecs.includes(codec);
   }

   eliminarPrefijo(arrayGroup: FormArray, index: number) {
      arrayGroup.removeAt(index);
   }

   modCodec(codec: string, checked: boolean) {
      // console.log(codec, checked);
      const codecs: { codec: string }[] = <{ codec: string }[]>(
         this.troncal.codec
      );
      if (!!checked) {
         // agregar
         codecs.push({ codec: codec });
      } else {
         codecs.splice(_.findIndex(codecs, ["codec", codec]), 1);
      }
   }

   guardarTroncal(frmTrunkValue?: {
      codec: { nombre: string; presente: boolean }[];
   }) {
      const getCodecFromForm = (
         codecs: { nombre: string; presente: boolean }[]
      ) =>
         codecs
            .filter((c) => c.presente)
            .map((objCodec) => ({ codec: objCodec.nombre }));

      // Combinar {...frmTrunkValue, ...getRawValue()}
      const formularioInmutable = this.formTroncal.getRawValue() as Troncal;
      const nombre = formularioInmutable.nombre.split(" ").join("_");
      const usuario = formularioInmutable.usuario?.split(" ").join("_");

      const valoresFormulario = {
         ...frmTrunkValue,
         codec: getCodecFromForm(frmTrunkValue.codec),
      } as Troncal;

      // Mix de los dos formularios.
      const troncalSerilizada = {
         ...formularioInmutable,
         ...valoresFormulario,
         nombre: nombre,
         usuario: !!usuario ? usuario : "",
         prefijos: valoresFormulario.prefijos.map((prefijo) => ({
            ...prefijo,
            troncal: nombre,
         })),
      };

      $("#modalWait_kerberus").modal();
      of(troncalSerilizada)
         .pipe(
            flatMap((troncal) =>
               this.$troncal
                  .actualizarTroncal({
                     troncal: troncal,
                     update: this.formTroncal.get("nombre").disabled,
                  })
                  .pipe(
                     concat(
                        this.$monitor.enviarComando({
                           comando: EComando.KERBERUS_FILE,
                           data: {
                              tipo: troncal.protocolo.toUpperCase() + "_TRUNK",
                           },
                        })
                     )
                  )
            ),
            tap(() => this.nuevaTroncal())
         )
         .subscribe(
            () => {
               this.ngOnInit();
               $("#modalWait_kerberus").modal("hide");
            },
            (err) => console.error(err)
         );
   }

   eliminarTroncal(troncal: Troncal) {
      $("#modalWait_kerberus").modal();
      this.$troncal
         .actualizarTroncal({
            troncal: troncal,
            update: undefined,
         })
         .pipe(
            flatMap(() =>
               this.$monitor.enviarComando({
                  comando: EComando.KERBERUS_FILE,
                  data: {
                     tipo: this.troncal.protocolo.toUpperCase() + "_TRUNK",
                  },
               })
            )
         )
         .subscribe(
            (res) => {
               console.log(res);
               $("#modalWait_kerberus").modal("hide");
               this.ngOnInit();
               this.nuevaTroncal();
            },
            (err) => console.log(err)
         );
   }
}
